<template>
  <AuthWrapper>
    <div class="auth-contents">
      <a-form ref="formRef" name="resetpassword" style="text-align: left;" :rules="formRules" :model="formState" @finish="handleSubmit" layout="vertical">
        <img :style="{ width: '210px' }" :src="require('@/assets/img/logo.png')" alt="" />
        <sdHeading as="h3">Reset Password</sdHeading>
        <a-form-item label="Password" name="password">
          <a-input type="password" v-model:value="formState.password" placeholder="Enter Password" />
        </a-form-item>
        <a-form-item label="Confirm Password" name="confirm_password">
          <a-input type="password" v-model:value="formState.confirm_password" placeholder="Enter confirm password" />
        </a-form-item>
        <a-form-item>
          <sdButton class="btn-create" htmlType="submit" type="primary" size="large">
            Update
          </sdButton>
        </a-form-item>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { API } from "@/services/api";
import { AuthWrapper } from './style';
import Notification from "@/services/Notification";

export default {
  name: 'ResetPassword',
  components: { AuthWrapper },
  computed: {
    formRef: function() {
      return this.$refs.formRef;
    }
  },
  data() {
    return {
      formState: {
        password: "",
        confirm_password: ""
      },
      formRules: {
        password: [{
          required: true, trigger: 'blur',
          message: 'Please input password',
        }],
        confirm_password: [{
          trigger: 'change',
          validator: this.validateConfirmPassword,
        }]
      },
    };
  },
  methods: {
    async handleSubmit() {
      const { replace, currentRoute } = this.$router;
      const { access_token = "" } = this.$route.params;
      const ini = currentRoute.value.path.split("/")[1];
      try {
        await this.formRef.validate();
        if (!access_token) {
          Notification.show("Access token not found", {type: "info", title: "Access token"});
          return null;
        }
        const fData = new FormData();
        fData.append("access_token", access_token);
        fData.append("password", this.formState.password);
        fData.append("confirm_password", this.formState.confirm_password);
        const res = await API.resetPssword(fData, ini);
        const { status, message = "" } = res.data;
        if (message) {
          Notification.show(message, {
            type: status ? "success" : "error",
            title: status ? "Password Update Success" : "Password Update Error",
          });
        }
        if (status) replace({path: `/${ini}/login`});
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, {type: "error", title: "Error"});
        }
      }
    },
    async validateConfirmPassword(rule, value) {
      if (value === '') {
        return Promise.reject('Please input confirm password');
      } else if (value !== this.formState.password) {
        return Promise.reject("Two inputs don't match!");
      } else {
        return Promise.resolve();
      }
    }
  },
};
</script>
